import { graphql } from '../gql';

export const GET_CATEGORIES = graphql(`
  query GetCategories($restaurantId: ID!) {
    categories(restaurantId: $restaurantId) {
      id
      type
      name
      img
    }
  }
`);

export const GET_PRODUCTS = graphql(`
  query GetProducts($restaurantId: ID!) {
    products(restaurantId: $restaurantId) {
      id
      price
      name
      img
      weight
      category {
        type
        name
      }
    }
  }
`);

export const GET_PRODUCT = graphql(`
  query GetProduct($id: ID!) {
    product(id: $id) {
      id
      weight
      img
      name
      price
      description
      calorific
      carbohydrates
      fat
      protein
    }
  }
`);

export const GET_RESTAURANTS = graphql(`
  query GetRestaurants {
    restaurants {
      id
      name
      deliveryTime
    }
  }
`);

export const GET_WORKING = graphql(`
  query GetWorking {
    working {
      restaurantId
      open
      close
    }
  }
`);

export const GET_ME = graphql(`
  query GetMe {
    getMe {
      telegramId
      name
      phone
      address
      district
    }
  }
`);

export const CHECK_PROMOCODE = graphql(`
  query CheckPromocode($promocodeInput: PromocodeInput!) {
    checkPromocode(promocodeInput: $promocodeInput) {
      id
      code
      discount
      maxDiscount
      minSum
      product {
        id
        name
        price
        img
        description
      }
    }
  }
`);
export const GET_DISTRICTS = graphql(`
  query GetDistricts {
    districts {
      id
      name
      deliveryPrice
      freeDeliveryFrom
    }
  }
`);
