/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation MakeOrder($orderInput: OrderInput!) {\n    makeOrder(orderInput: $orderInput)\n  }\n": types.MakeOrderDocument,
    "\n  query GetCategories($restaurantId: ID!) {\n    categories(restaurantId: $restaurantId) {\n      id\n      type\n      name\n      img\n    }\n  }\n": types.GetCategoriesDocument,
    "\n  query GetProducts($restaurantId: ID!) {\n    products(restaurantId: $restaurantId) {\n      id\n      price\n      name\n      img\n      weight\n      category {\n        type\n        name\n      }\n    }\n  }\n": types.GetProductsDocument,
    "\n  query GetProduct($id: ID!) {\n    product(id: $id) {\n      id\n      weight\n      img\n      name\n      price\n      description\n      calorific\n      carbohydrates\n      fat\n      protein\n    }\n  }\n": types.GetProductDocument,
    "\n  query GetRestaurants {\n    restaurants {\n      id\n      name\n      deliveryTime\n    }\n  }\n": types.GetRestaurantsDocument,
    "\n  query GetWorking {\n    working {\n      restaurantId\n      open\n      close\n    }\n  }\n": types.GetWorkingDocument,
    "\n  query GetMe {\n    getMe {\n      telegramId\n      name\n      phone\n      address\n      district\n    }\n  }\n": types.GetMeDocument,
    "\n  query CheckPromocode($promocodeInput: PromocodeInput!) {\n    checkPromocode(promocodeInput: $promocodeInput) {\n      id\n      code\n      discount\n      maxDiscount\n      minSum\n      product {\n        id\n        name\n        price\n        img\n        description\n      }\n    }\n  }\n": types.CheckPromocodeDocument,
    "\n  query GetDistricts {\n    districts {\n      id\n      name\n      deliveryPrice\n      freeDeliveryFrom\n    }\n  }\n": types.GetDistrictsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MakeOrder($orderInput: OrderInput!) {\n    makeOrder(orderInput: $orderInput)\n  }\n"): (typeof documents)["\n  mutation MakeOrder($orderInput: OrderInput!) {\n    makeOrder(orderInput: $orderInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCategories($restaurantId: ID!) {\n    categories(restaurantId: $restaurantId) {\n      id\n      type\n      name\n      img\n    }\n  }\n"): (typeof documents)["\n  query GetCategories($restaurantId: ID!) {\n    categories(restaurantId: $restaurantId) {\n      id\n      type\n      name\n      img\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetProducts($restaurantId: ID!) {\n    products(restaurantId: $restaurantId) {\n      id\n      price\n      name\n      img\n      weight\n      category {\n        type\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProducts($restaurantId: ID!) {\n    products(restaurantId: $restaurantId) {\n      id\n      price\n      name\n      img\n      weight\n      category {\n        type\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetProduct($id: ID!) {\n    product(id: $id) {\n      id\n      weight\n      img\n      name\n      price\n      description\n      calorific\n      carbohydrates\n      fat\n      protein\n    }\n  }\n"): (typeof documents)["\n  query GetProduct($id: ID!) {\n    product(id: $id) {\n      id\n      weight\n      img\n      name\n      price\n      description\n      calorific\n      carbohydrates\n      fat\n      protein\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetRestaurants {\n    restaurants {\n      id\n      name\n      deliveryTime\n    }\n  }\n"): (typeof documents)["\n  query GetRestaurants {\n    restaurants {\n      id\n      name\n      deliveryTime\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetWorking {\n    working {\n      restaurantId\n      open\n      close\n    }\n  }\n"): (typeof documents)["\n  query GetWorking {\n    working {\n      restaurantId\n      open\n      close\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMe {\n    getMe {\n      telegramId\n      name\n      phone\n      address\n      district\n    }\n  }\n"): (typeof documents)["\n  query GetMe {\n    getMe {\n      telegramId\n      name\n      phone\n      address\n      district\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CheckPromocode($promocodeInput: PromocodeInput!) {\n    checkPromocode(promocodeInput: $promocodeInput) {\n      id\n      code\n      discount\n      maxDiscount\n      minSum\n      product {\n        id\n        name\n        price\n        img\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  query CheckPromocode($promocodeInput: PromocodeInput!) {\n    checkPromocode(promocodeInput: $promocodeInput) {\n      id\n      code\n      discount\n      maxDiscount\n      minSum\n      product {\n        id\n        name\n        price\n        img\n        description\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetDistricts {\n    districts {\n      id\n      name\n      deliveryPrice\n      freeDeliveryFrom\n    }\n  }\n"): (typeof documents)["\n  query GetDistricts {\n    districts {\n      id\n      name\n      deliveryPrice\n      freeDeliveryFrom\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;