import { Section } from '../../styles/components';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Header } from './Header/Header';
import * as S from './styles';
import { getLocalCountValue } from '../../functions/getLocalCountValue';
import { CartPosition } from './CartPosition/CartPosition';
import {
  GET_ME,
  GET_PRODUCTS,
  GET_RESTAURANTS,
  GET_WORKING,
} from '../../graphql/Query';
import { Product, Working } from '../../gql/graphql';
import { useCart } from '../../hooks/useCart';
import { useTgMainBtn } from '../../hooks/useTgMainBtn';
import { useReloadScrollTop } from '../../hooks/useReloadScrollTop';
import { useTgBack } from '../../hooks/useTgBack';
import { useTelegram } from '../../hooks/useTelegram';
import { Total } from './Total/Total';
import { PromocodeComponent } from './Promocode/PromocodeComponent';
import { formDataVar, promocodeVar } from '../../graphql/Cache';
import { Toggler } from '../../components/Toggler/Toggler';
import { FormDataType } from '../Order/Form/Form';

export const MIN_ORDER_SUM = 2_000;

const isOpen = (working: Working) => {
  const open = working.open.substring(0, 8);
  const close = working.close.substring(0, 8);
  const time = new Date().toLocaleTimeString('ru');

  if (time.startsWith('0')) {
    if (Number(time.substring(0, 2)) < 4)
      return close.startsWith('0') && time < close;

    return time > open;
  }

  return open < time && (close.startsWith('0') || close > time);
};

export const CartPage = () => {
  const promocode = useReactiveVar(promocodeVar);
  const formData = useReactiveVar(formDataVar);
  const { data: me, refetch } = useQuery(GET_ME, {
    onError: (err) => console.log(err),
  });
  const { data: working } = useQuery(GET_WORKING, {
    onError: (err) => console.log(err),
  });
  const { data: restsData } = useQuery(GET_RESTAURANTS, {
    onError: (error) => console.log(error),
  });
  const { tg } = useTelegram();
  const { setText } = useTgMainBtn();
  const navigate = useNavigate();
  const restaurant = !!localStorage.getItem('restaurantId')
    ? (localStorage.getItem('restaurantId') as string)
    : '1';
  const restData =
    restsData &&
    restsData.restaurants &&
    restsData.restaurants.find((r) => r!.id === restaurant);
  const { cart } = useCart();
  const items = cart[restaurant];
  const workOfRest =
    working &&
    working.working &&
    working.working.find((w) => w?.restaurantId.toString() === restaurant);
  const { data, loading } = useQuery(GET_PRODUCTS, {
    variables: {
      restaurantId: restaurant,
    },
  });

  const totalPrice =
    items &&
    items.length &&
    items
      .map(({ price, count }) => price * count)
      .reduce((val, acc) => acc + val);

  useTgBack();

  useReloadScrollTop();

  useEffect(() => {
    if (!items || !items.length) navigate('/');
  }, [cart]);

  useEffect(() => {
    refetch();
  }, [tg]);

  const preCount =
    items &&
    items.length &&
    items.map(({ count }) => count).reduce((val, acc) => val + acc);
  const itemsCount =
    !!promocode?.product && totalPrice >= promocode.minSum
      ? preCount + 1
      : preCount;

  const onShare = () => {
    tg?.requestContact((isShared) => {
      if (isShared) refetch();
    });
  };

  const onClick = () => {
    if (!me || !me.getMe) onShare();
    else if (formData?.delivery !== 'self' && totalPrice < MIN_ORDER_SUM)
      tg?.showPopup({
        title: 'Внимание',
        message:
          'Минимальная сумма заказа 2000 рублей, добавьте в свой заказ еще позиций',
      });
    else if (workOfRest && !isOpen(workOfRest as Working))
      tg?.showPopup({
        title: 'Внимание',
        message:
          'Ресторан сейчас не работает, дождитесь открытия для оформления заказа',
      });
    else navigate('/order');
  };

  useEffect(() => {
    if (!tg) return;
    setText('К оформлению');
  }, [tg]);

  useEffect(() => {
    if (!tg) return;
    tg.MainButton.onClick(onClick);
    return () => {
      tg.MainButton.offClick(onClick);
    };
  }, [tg, cart, formData]);

  if (!items || !items.length) return null;
  return (
    <Section style={{ minHeight: '100vh' }}>
      <Header heading={'Корзина'} toText={'Назад к меню'} to={'/'} />
      <S.CountItems>
        {getLocalCountValue(itemsCount, 'позиция', 'позиции', 'позиций')}
      </S.CountItems>
      {!!data &&
        !!data.products &&
        data.products
          .filter((i) => items.map(({ id }) => id).indexOf(i!.id) >= 0)
          .map((product) => (
            <CartPosition
              key={product!.id}
              product={product as Product}
              count={items.find((i) => i.id === product!.id)!.count ?? 1}
            />
          ))}
      {!!promocode?.product && promocode.minSum <= totalPrice && (
        <CartPosition product={promocode.product} count={1} isBonus={true} />
      )}
      <hr />
      <PromocodeComponent />
      <Toggler
        title={`Самовывоз на ${restData?.name}`}
        isChecked={formData?.delivery === 'self'}
        onChange={() => {
          formDataVar({
            ...formData,
            delivery: formData?.delivery === 'self' ? 'courier' : 'self',
          } as FormDataType);
        }}
        mb={2}
      />
      {workOfRest && !isOpen(workOfRest as Working) && (
        <S.RedText>
          Ресторан закрыт, вы сможете заказать после открытия.
        </S.RedText>
      )}
      <Total
        itemsCount={itemsCount}
        totalPrice={totalPrice}
        isSelf={formData?.delivery === 'self'}
      />
      {process.env.NODE_ENV !== 'production' && (
        <Link to={'/order'}>Order</Link>
      )}
    </Section>
  );
};
